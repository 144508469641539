import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4169f612 = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _c9ab7784 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _6260aedc = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _54cd3dd1 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _f011a2dc = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _49178286 = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _678492ae = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _87391fce = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _e8b29064 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _71c9633b = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _90dd9120 = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _ebdab36a = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _656cfbac = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _06466a68 = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _e0b2a130 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _c8414ec0 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _85746ce4 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _3599165c = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _7be31654 = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _6e834b15 = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _e8bf95ea = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _3a2320ea = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _55ea0ef9 = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _43a97410 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _06b712d8 = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _ccd7329c = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _297c3e8c = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _5952cac9 = () => interopDefault(import('../client/pages/products/search/mixin.js' /* webpackChunkName: "pages/products/search/mixin" */))
const _0d718e88 = () => interopDefault(import('../client/pages/products/search/search.js' /* webpackChunkName: "pages/products/search/search" */))
const _e849a896 = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _db360a8a = () => interopDefault(import('../client/pages/sso/punch-out/_source.vue' /* webpackChunkName: "pages/sso/punch-out/_source" */))
const _5c3abc67 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _2f0c6138 = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _294f45a8 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _5976ef3a = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _ce85d426 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _02a251f2 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _383062f6 = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _331efd65 = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _e14eb2ce = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _3d864483 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _ddc596d8 = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _3951beee = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _cd8d9f66 = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _a7db2d7c = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _7dcf3a10 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _686b7483 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _60059ae8 = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _10421dfd = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _692672de = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _74d7dd5a = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _299b0802 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _c38042ec = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _2958ad24 = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _10b649b6 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _37de88aa = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _4ccb3a1f = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _70fad6b2 = () => interopDefault(import('../client/pages/printinvoice/_orderId/short.vue' /* webpackChunkName: "pages/printinvoice/_orderId/short" */))
const _375f50cc = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _0d636ad4 = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _531602eb = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _4e1e61fc = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _14a11c80 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _4169f612,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _4169f612,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _4169f612,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _4169f612,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _4169f612,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _4169f612,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _4169f612,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _4169f612,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _c9ab7784,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _6260aedc,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _54cd3dd1,
    name: "account"
  }, {
    path: "/cart",
    component: _4169f612,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _f011a2dc,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _49178286,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _678492ae,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _87391fce,
    name: "designs"
  }, {
    path: "/facets",
    component: _e8b29064,
    name: "facets"
  }, {
    path: "/favorites",
    component: _71c9633b,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _90dd9120,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _ebdab36a,
    name: "health"
  }, {
    path: "/not-found",
    component: _656cfbac,
    name: "not-found"
  }, {
    path: "/proof",
    component: _06466a68,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _e0b2a130,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _c8414ec0,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _85746ce4,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _3599165c,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _7be31654,
    name: "signin"
  }, {
    path: "/signout",
    component: _6e834b15,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _e8bf95ea,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _3a2320ea,
    name: "testy"
  }, {
    path: "/version",
    component: _55ea0ef9,
    name: "version"
  }, {
    path: "/version-app",
    component: _43a97410,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _06b712d8,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _ccd7329c,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _297c3e8c,
    name: "products-utils"
  }, {
    path: "/products/search/mixin",
    component: _5952cac9,
    name: "products-search-mixin"
  }, {
    path: "/products/search/search",
    component: _0d718e88,
    name: "products-search-search"
  }, {
    path: "/sso/facebook/delete",
    component: _e849a896,
    name: "sso-facebook-delete"
  }, {
    path: "/sso/punch-out/:source?",
    component: _db360a8a,
    name: "sso-punch-out-source"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _5c3abc67,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _2f0c6138,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _c9ab7784,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _6260aedc,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _294f45a8,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _5976ef3a,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _ce85d426,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _02a251f2,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _383062f6,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _331efd65,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _e14eb2ce,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _3d864483,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _ddc596d8,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _3951beee,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _cd8d9f66,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _a7db2d7c,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _7dcf3a10,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _686b7483,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _60059ae8,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _10421dfd,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _692672de,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _74d7dd5a,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _299b0802,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _c38042ec,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _2958ad24,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _10b649b6,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _37de88aa,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _4ccb3a1f,
    name: "orders-id-track-shipment"
  }, {
    path: "/printinvoice/:orderId?/short",
    component: _70fad6b2,
    name: "printinvoice-orderId-short"
  }, {
    path: "/products/:slug?/reviews",
    component: _375f50cc,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _0d636ad4,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _531602eb,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _4e1e61fc,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _14a11c80,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
